import React from "react";

import LiteYouTubeEmbed from "react-lite-youtube-embed";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

export default function YoutubeWidget({ id }) {
  return (
    <div className="u-video-responsive">
      <LiteYouTubeEmbed id={id} title="YouTube video" aspectHeight={1080} aspectWidth={1920} thumbnail={"https://i3.ytimg.com/vi/"+id+"/maxresdefault.jpg"}/>
    </div>
  );
}
