import React from "react";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

import Button from "../../button/button";

import "./interviewCarousel.scss";

import InterviewItem from "./interviewItem";

export default function InterviewCarousel({ interviews, noBackground = false }) {
  const slides = [];
  interviews.forEach((interview) => {
    slides.push(<InterviewItem interview={interview} />);
  });

  return (
    <div className={'client-carousel ' + (!noBackground ? 'enable-background' : '')}>
      {slides.length === 1 ? (
        <InterviewItem interview={interviews[0]} />
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          centeredSlides={true}
          roundLengths={true}
          navigation={true}
          loop={true}
          pagination={{
            clickable: true
          }}
          autoHeight={true}
        >
          {interviews.splice(0, 3).map((interview, index) => (
            <SwiperSlide key={index}>
              <InterviewItem interview={interview} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <Button
        to="https://www.youtube.com/watch?v=zJrfqTuDYXk&list=PLYreGZrtNcMYGkae9PhOr8osrnuQ1Q5pH"
        className="client-carousel__btn"
        text="de témoignages"
      />
    </div>
  );
}
