import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import "./interviewCarousel.scss";

import YoutubeWidget from "../../youtubeWidget/youtubeWidget";

export default function InterviewItem({ interview }) {
  return (
    <div className="client-carousel__slide">
      <div className="client-carousel__video">
        <YoutubeWidget id={interview.VideoId} />
      </div>
      <div className="client-carousel__text">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          className="client-carousel__main-text"
          children={interview.Presentation.data}
        />
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          className="client-carousel__secondary-text"
          children={interview.Citation.data}
        />
      </div>
    </div>
  );
}
