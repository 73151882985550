import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./citationCard.scss";

export default function CitationCard({ client }) {
  return (
    <div className="citation-card">
      <div className="citation-card__layering">
        <div>
          <GatsbyImage
            image={getImage(client.Photo.localFile)}
            alt={`Photo de ${client.Nom}`}
          />
        </div>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          className="citation-card__text"
          children={client.Citation}
        />  
      </div>
    </div>
  );
}
